 @import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
:root {
  --blue: #0e0620;
  --white: #fff;
  --green: #253b80;
}
main.sesstion_404 {
    height: 100vh;
    align-items: center;
    display: flex;
    background: white;
}
.page404 button {
  font-family: "Nunito Sans";
}

.page404 h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: bold;
}

.page404 h2 {
  font-weight: bold;
}

.btn404 {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
}
.btn404.green {
  border: 4px solid var(--green);
  color: var(--blue);
}
.btn404.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: var(--green);
  z-index: -1;
  transition: 0.2s ease;
}
.btn404.green:hover {
  color: var(--white);
  background: var(--green);
  transition: 0.2s ease;
}
.btn404.green:hover:before {
  width: 100%;
}

@media screen and (max-width: 768px) {
 

  .pageCss {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
